import React from 'react';
import styled from 'styled-components';
import color from '../color';
import TextBox from '../components/TextBox';

const Container = styled.div`
    background-color: ${color.base};
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const CopyrightContainer = styled.div`
    display: flex;
    max-width: 980px;
    width: 100%;
    height: 30px;
    justify-content: center;
    align-items: center;
`;

const MenuBar = styled.nav`
    display: flex;
    max-width: 980px;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
`;

const MenuLink = styled.a`
    color: var(--white);
    text-decoration: none;
    margin: 5px;
    padding: 5px;
`;

function Footer() {
    return (
        <Container>
            <MenuBar>
                <MenuLink href="#bla">Data privacy</MenuLink>
                <MenuLink href="#bla">Imprint</MenuLink>
            </MenuBar>
            <CopyrightContainer>
                <TextBox>Copyright &copy;2021</TextBox>
            </CopyrightContainer>
        </Container>
    );
}

export default Footer;
