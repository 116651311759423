import React from 'react';
import styled from 'styled-components';
import Navigation from './ui/Navigation';
import Slogan from './ui/Slogan';
import Features from './ui/Features';
import Team from './ui/Team';
import Footer from './ui/Footer';
import Roadmap from './ui/Roadmap';
import Contact from './ui/Contact';

const Container = styled.div``;

function App() {
  return (
    <Container>
      <Navigation />
      <Slogan />
      <Features />
      <Team />
      <Roadmap />
      <Contact />
      <Footer />
    </Container>
  );
}

export default App;
