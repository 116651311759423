import React from 'react';
import styled from 'styled-components';
import color from '../color';
import background from '../assets/slogan-background.jpeg';
import playstore from '../assets/google-play-badge.png';
import appstore from '../assets/apple-app-store-badge.svg';

const Container = styled.div`
    background-color: ${color.background};
    background-image: var(--gradient-transparent), url(${background});
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 980px;
    width: 100%;
    padding: 5px;
    text-align: center;
`;

const Image = styled.img`
    width: 100%;
`;

const Link = styled.a`
    color: white;
    text-decoration: none;
    margin: 10px;
    width: 200px;
`;

const SloganText = styled.p`
    font-size: 1.5em;
    font-weight: bold;
    color: var(--white);
    word-wrap: break-word;
    margin: 10px;
`

function Slogan() {
    return (
        <Container>
            <Row>
                <SloganText>SOME NICE SLOGAN HERE</SloganText>
            </Row>
            <Row>
                <Link href="#" target="_blank">
                    <Image src={appstore} alt={'apple app store download image'} />
                </Link>
                <Link href="#" target="_blank">
                    <Image src={playstore} alt={'google play store download image'} />
                </Link>
            </Row>
        </Container>
    );
}

export default Slogan;
