import React from 'react';
import styled from 'styled-components';
import color from '../color';

interface Props {
    children: JSX.Element | string;
}

const Text = styled.h1`
    color: ${color.base};
    font-size: 2.5em;
    display: block;
    padding: 15px;
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
`;

function SectionHeader({ children } : Props) {
    return (
        <Text>{children}</Text>
    );
}

export default SectionHeader;
