import styled, { css } from 'styled-components';
import React from 'react';

type Type = 'primary' | 'secondary1' | 'white';

interface Props {
    href?: string;
    onClick?: (event: React.MouseEvent) => void;
    type: Type;
    wide?: boolean;
    children: React.ReactNode;
}

const ButtonComponent = styled.a<{ wide: boolean; type: Type }>`
  line-height: 1.5;
  font-weight: normal;
  display: inline-block;
  padding: 15px 30px;
  border: 1px solid var(--${(props) => props.type});
  color: var(--${(props) => props.type});
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.5s, color 0.5s;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: var(--${(props) => props.type});
    color: var(--${(props) => (props.type === 'white' ? 'primary' : 'white')});
  }

  ${(props) =>
    props.wide &&
    css`
      width: 100%;
      box-sizing: border-box;
    `}
`;

export const Button: React.FC<Props> = ({ href, onClick, type, wide = false, children }) => {
    return (
        <ButtonComponent onClick={onClick} href={href} type={type} wide={wide}>
            {children}
        </ButtonComponent>
    );
};
