const base = '#0ca678';
const secondary = '#ff922b';
const secondary2 = '#d31ab4';
const background = '#f5f6f5';
const backgroundLight = '#efefef';
const light = '#aeb0b4';
const white = '#FFF';
const black = '#000';
const font = '#000';

export default {
  base,
  secondary,
  light,
  white,
  black,
  background,
  backgroundLight,
  font,
};
