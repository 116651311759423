import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../components/SectionHeader';
import color from '../color';
import Marco from '../assets/profile/marco.jpg';
import Anonym from '../assets/profile/anonym.png';
import TextBox from '../components/TextBox';

const Container = styled.div`
    background-color: ${color.backgroundLight};
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: center;
    scroll-margin-top: 60px;
`;

const MemberRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const Member = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin 20px;
`;

const Image = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 100px;
    border: 1px solid ${color.secondary};
`;

function Team() {
    return (
        <Container id="team">
            <SectionHeader>
                Team
            </SectionHeader>
            <MemberRow>
                <Member>
                    <Image src={Marco} alt={'Picture of Marco'} />
                    <TextBox>Marco</TextBox>
                </Member>
                <Member>
                    <Image src={Anonym} alt={'Picture of Karsten'} />
                    <TextBox>Karsten</TextBox>
                </Member>
                <Member>
                    <Image src={Anonym} alt={'Picture of Tyskie'} />
                    <TextBox>Tyskie</TextBox>
                </Member>
            </MemberRow>
        </Container>
    );
}

export default Team;
