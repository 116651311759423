import React from 'react';
import styled from 'styled-components';
import color from '../color';

interface Props {
    children: JSX.Element | string;
}

const Text = styled.p`
    color: ${color.font};
    font-size: 1.3em;
    display: block;
    padding: 25px;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
`;

function TextBox({ children } : Props) {
    return (
        <Text>{children}</Text>
    );
}

export default TextBox;
