import React from 'react';
import styled from 'styled-components';
import TextBox from '../components/TextBox';
import appProfileScreenshot from '../assets/doggo-app-profile.png'
import appMarkersScreenshot from '../assets/doggoa-app-markers.png'
import appTrackingScreenshot from '../assets/doggo-app-tracking.png'
import Headline from '../components/Headline';

const Container = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    scroll-margin-top: 60px;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 980px;
    width: 100%;
    padding: 5px;
`;

const TextCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 50%;
    justify-content: center;

    @media (max-width: 400px) {
        order: 1;
    }
`;

const ImageCol = styled(TextCol)`
    align-items: center;
    
    @media (max-width: 400px) {
        order: 2;
    }
`;

const Image = styled.img`
    max-width: 300px;
`;

function Features() {
    return (
        <Container id="features">
            <Row>
                <TextCol>
                    <Headline>Markers</Headline>
                    <TextBox>Markers can be used to mark something dangerous on a current spot, so other people are alarmed. Beside that you can use those markers to go for a group walk with other people.</TextBox>
                </TextCol>
                <ImageCol>
                    <Image src={appMarkersScreenshot} alt={'create map marker'} />
                </ImageCol>
            </Row>
            <Row>
                <ImageCol>
                    <Image src={appProfileScreenshot} alt={'see your profile'} />
                </ImageCol>
                <TextCol>
                    <Headline>Profile</Headline>
                    <TextBox>Some example text about the profile. Current Features: statistics about your recent walk (time, distance, count). Detailed overview of your dogs with a cute thumbnail.</TextBox>
                </TextCol>
            </Row>
            <Row>
                <TextCol>
                    <Headline>Tracking</Headline>
                    <TextBox>Some example Text about tracking: the tracking is used to gather some data about for actual walks with your dogs.</TextBox>
                </TextCol>
                <ImageCol>
                    <Image src={appTrackingScreenshot} alt={'track your own walk with your dog'} />
                </ImageCol>
            </Row>
        </Container>
    );
}

export default Features;
