import React from 'react';
import styled from 'styled-components';
import color from '../color';

const Container = styled.div`
    background-color: ${color.base};
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 1;
`;

const Logo = styled.div`
    display: flex;
    max-width: 260px;
    width: 100%;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
`;

const MenuBar = styled.nav`
    display: flex;
    max-width: 700px;
    width: 100%;
    height: 60px;
    justify-content: flex-end;
    align-items: center;
    
    @media (max-width: 500px) {
        display: none;
    }
`;

const Menu = styled.ul`
    display: flex;
    max-width: 700px;
    width: 100%;
    height: 50px;
    justify-content: flex-end;
    align-items: center;
`;

const MenuItem = styled.li`
    list-style: none;
    color: white;
`;

const MenuLink = styled.a`
    color: white;
    text-decoration: none;
    padding: 15px 30px;
    display: block;
    font-weight: bold;
`;

function Navigation() {
    return (
        <Container>
            <Logo>
                <p>LOGO</p>
            </Logo>
            <MenuBar>
                <Menu>
                    <MenuItem>
                        <MenuLink href="#features">Preview</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#team">Team</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#roadmap">Roadmap</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink href="#contact">Contact</MenuLink>
                    </MenuItem>
                </Menu>
            </MenuBar>
        </Container>
    );
}

export default Navigation;
