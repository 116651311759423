import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../components/SectionHeader';

const Container = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-margin-top: 60px;
`;

const Text = styled.p`
    color: var(--black);
    font-size: 1.3em;
    display: block;
    padding: 5px;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
    margin: 10px;
`;

function Roadmap() {
    return (
        <Container id="roadmap">
            <SectionHeader>
                Roadmap
            </SectionHeader>
            <Text>
                To be announced soon.... (yes yes, we do have some plans for the future as well)
            </Text>
            <Text>
                This is mostly regarding bug fixes, monetizing and new features.
            </Text>
        </Container>
    );
}

export default Roadmap;
